import styled, { device, css } from "@styled";

export const StyledSection = styled.section`
    padding-block-start: 0;
    padding-block-end: 80px;

    
    ${device.medium} {
        padding-block-start: 0;
        padding-block-end: 60px;
    }
    ${device.large} {
        padding-block-start: 0;
        padding-block-end: 80px;
    }
    .row_reversed {
        flex-direction: column-reverse;
        ${device.large} {
            flex-direction: initial;
        }
    }
    .section-title {
        margin-bottom: 15px;
        margin-top: 30px;
        ${device.large} {
            margin-top: 0;
        }
        ${device.xlarge} {
            margin-bottom: 20px;
        }
        ${device.xxlarge} {
            margin-bottom: 30px;
        }
        h2 {
            border: none;
            padding: 0;
            font-size: 24px;
            ${device.xlarge} {
                font-size: 26px;
            }
            ${device.xxlarge} {
                font-size: 30px;
            }
        }
        p {
            margin-top: 15px;
            ${device.xlarge} {
                margin-top: 15px;
            }
            ${device.xxlarge} {
                margin-top: 20px;
            }
        }
    }
    ${(props) =>
        props.layout === 2 &&
        css`
        padding-block-start: 80px;
        padding-block-end: 80px;

            .row_reversed {
                flex-direction: column-reverse;;
                ${device.large} {
                    flex-direction: row-reverse;
                }
            }
            
            ${device.medium} {
                padding-block-start: 100px;
                padding-block-end: 100px;
            }
            ${device.large} {
                padding-block-start: 110px;
                padding-block-end: 110px;
            }
        `}
        .row {
            align-items: center;
        }
        h5 {
            font-size: 24px;
            letter-spacing: 0px;
            color: #212121;
            font-weight: bold;
            margin-bottom: 30px;
            line-height: 1.6;
            margin: 20px 0 10px;
            ${device.medium} {
                font-size: 30px;
                margin: 0;
            }
            ${device.large} {
                margin-top: 
                font-size: 40px;
            }
        }
        button {
            line-height: 1;
            &.clicked {
                background-color: #fff;
                color: #e14629;
                .btn-icon {
                    transform: rotate(90deg);
                }
            }
            .btn-icon {
                font-size: 26px;
                width: 14px;
                height: 14px;
                transition: transform 0.3s;
            }
        }
        .marketingList {
            li {
                padding-left: 1.5em;
                font-size: 16px;
                letter-spacing: 0px;
                color: #343434;
                margin-bottom: 6px;
                ${device.medium} {
                    font-size: 18px;
                    margin-bottom: 8px;
                }
                ${device.xlarge} {
                    font-size: 20px;
                    margin-bottom: 12px;
                }
                ${device.xxlarge} {
                    font-size: 22px;
                }
                &:before {
                    content: "";
                    position: absolute;
                    top: 5px;
                    left: 0;
                    display: block;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background: linear-gradient(-50deg,#5f1a7e 20%,#e14629 80%);
                    border: 1px solid #fff;
                }
            }   
        }
        .additional-list {
            .marketingList {
                display: block;
                margin-top: 30px;
                ${device.medium} {
                    margin-top: 80px;
                }
                li {
                    &:not(:last-child) {
                        margin-bottom: 20px;
                    }
                    &::before {
                        background: #e14629;
                    }
                }
            }
        }
`;