import React, { useState } from "react";
import { Container, Row, Col } from "@ui/wrapper";
import Image from "@ui/image";
import SectionTitle from "@ui/section-title";
import Heading from "@ui/heading";
import Button from "@ui/button";
import Anchor from "@ui/anchor";
import List, { ListItem } from "@ui/list";
import {
  BoxSectionWrapper,
  BoxSectionInner,
  BoxSectionWrap,
  BoxSectionWrapInner,
  BoxSectionIconwrap,
  BoxSectionTextWrap,
} from "./style";

const BoxSection = ({ data }) => {
  const [showList, setShowList] = useState(false);
  function showIntroList() {
    setShowList((isOpen) => !isOpen);
  }
  return (
    <BoxSectionWrapper id={data.section}>
      <BoxSectionInner>
        <Container>
          <Row>
            <Col>
              <SectionTitle
                title={data.section_title?.title}
                description={data.section_title?.description}
              />
              <List className="marketingList mb-5" $layout="circle">
                {data.items?.slice(0, 3).map((item, index) => (
                  <ListItem key={index} dangerouslySetInnerHTML={{ __html: item.title }}></ListItem>
                ))}
              </List>
            </Col>
          </Row>
          <Row>
            <Col>
              <BoxSectionWrap className="boxSectionWrap">
                {data &&
                  data.items?.slice(3, 7).map((el, index) => (
                    <Anchor path={el.path} key={index}>
                      <BoxSectionWrapInner>
                        <BoxSectionIconwrap>
                          <Image
                            src={el.images[0]?.src}
                            alt={el.images[0]?.alt || "Icon"}
                          />
                        </BoxSectionIconwrap>
                        <BoxSectionTextWrap>
                          <Heading as="h4" className="text-black">
                            {el.title}
                          </Heading>
                        </BoxSectionTextWrap>
                      </BoxSectionWrapInner>
                    </Anchor>
                  ))}
              </BoxSectionWrap>
              {data.buttons &&
                data?.buttons.slice(0, 1)?.map(({ id, content, ...rest }) => (
                  <Button key={id} mt='30px' {...rest} onClick={showIntroList} className={showList ? 'clicked' : ''}>
                    {content}
                  </Button>
                ))}
            </Col>
          </Row>

          {showList &&
            <Row className="additional-list">
              <Col lg={12}>
                <List className="marketingList" $layout="circle">
                  {data.items?.slice(7).map((item, index) => (
                    <ListItem key={index} dangerouslySetInnerHTML={{ __html: item.title }}></ListItem>
                  ))}
                </List>
                {data.buttons &&
                data?.buttons.slice(1)?.map(({ id, content, ...rest }) => (
                  <Button key={id} mt='30px' {...rest} onClick={showIntroList} target="_blank">
                    {content}
                  </Button>
                ))}
              </Col>
            </Row>
          }

        </Container>
      </BoxSectionInner>
    </BoxSectionWrapper>
  );
};

export default BoxSection;
