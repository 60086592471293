import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import Image from "@ui/image";
import Button from "@ui/button";
import List, { ListItem } from "@ui/list";
import { StyledSection } from "./style";
import SectionTitle from "@ui/section-title";

const MarketingContent = ({ data, layout, ...props }) => {

    const [showList, setShowList] = useState(false);
    function showIntroList() {
        setShowList((isOpen) => !isOpen);
    }
    return (
        <StyledSection layout={layout} {...props} id={data.section}>
            <Container>
                <Row className="row_reversed">
                    <Col lg={7}>
                        <SectionTitle
                            title={data.section_title?.title}
                            description={
                                data.section_title?.description
                            }
                        />
                        <List className="marketingList" $layout="circle">
                            {data.items?.slice(0, 3).map((item, index) => (
                                <ListItem key={index} dangerouslySetInnerHTML={{ __html: item.title }}></ListItem>
                            ))}
                        </List>
                        {data.buttons &&
                            data?.buttons.slice(0, 1)?.map(({ id, content, ...rest }) => (
                                <Button key={id} mt='30px' {...rest} onClick={showIntroList} className={showList ? 'clicked' : ''}>
                                    {content}
                                </Button>
                            ))}
                    </Col>
                    <Col lg={5}>
                        <Image
                            src={data.images[0]?.src}
                            alt={data.images[0]?.alt || "Andovar Studios"}
                            title={data.images[0]?.alt || "Andovar Studios"}
                        />
                    </Col>
                </Row>
                {showList &&
                    <Row className="additional-list">
                        <Col lg={12}>
                            <List className="marketingList" $layout="circle">
                                {data.items?.slice(3).map((item, index) => (
                                    <ListItem key={index} dangerouslySetInnerHTML={{ __html: item.title }}></ListItem>
                                ))}
                            </List>
                        </Col>
                    </Row>
                }
            </Container>
        </StyledSection>
    );
};

MarketingContent.propTypes = {
    layout: PropTypes.oneOf([1, 2]),
};

MarketingContent.defaultProps = {
    layout: 1,
};

export default MarketingContent;
